export const getNavigator = function(browser) {

  if (browser == "firefox" ) {
    return typeof InstallTrigger !== 'undefined';
  }
  else if (browser == "opera" ) {
    return (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  }
  else if (browser == "safari" ) {
    return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  }
  else if (browser == "ie" ) {
    return /*@cc_on!@*/false || !!document.documentMode;
  }
  else if (browser == "edge" ) {
    
  }
  else if (browser == "chrome" ) {
    return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  }
}